import * as React from 'react';
import VisibleAccountList from '../../containers/VisibleAccountList';
import SearchBar from '../../containers/SearchBar';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import styles from './FilterableAccountList.module.scss';
import { ChevronDownIcon, ChevronUpIcon } from '@primer/octicons-react';
import VisibleAdvancedSearch from '../../containers/VisibleAdvancedSearch';
import styled from 'styled-components';
import { InfoTooltip } from '../shared/InfoTooltip';
import { useTheme } from '../../styles/useTheme';
import { aprilFools } from '../../styles/theme';

export const FilterableAccountList: React.FC = React.memo(() => {
  const [isOpen, setOpen] = React.useState(true);
  const toggleOpen = () => setOpen(!isOpen);
  
  const [theme] = useTheme();

  const titleText = theme.id === aprilFools.id ? 'Timelines' : 'Accounts';

  return (
    <FilterableAccountListCard className={styles.filterableAccountList}>
      <Header className={styles.header}>
        <span>{titleText}</span>
        <DropDownButton className={styles.dropdownButton} onClick={toggleOpen}>
          {isOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}
        </DropDownButton>
      </Header>
      <Collapse isOpen={isOpen} className={styles.collapse}>
        <CardBody className={styles.body}>
          <div className={styles.searchBar}>
            <div
              data-tip={true}
              data-for="searchInfo"
              className={styles.searchBarRow}
            >
              <SearchBar autoFocus={true} aria-label="filter accounts" />
              <InfoTooltip
                style={{ marginTop: '8px', height: '16px' }}
                disableInteractive={true}
                title={
                  <React.Fragment>
                    <p>
                      Try typing here to filter accounts by name, alias, ID, or
                      tags.
                    </p>
                    Prepend terms with <code>+</code> or <code>-</code> to
                    include/exclude accounts who have exact-matching tags. For
                    example, <code>+blue</code>
                  </React.Fragment>
                }
              />
            </div>
            <VisibleAdvancedSearch />
            <hr className="my-2" />
          </div>
          <div className={styles.accountList}>
            <VisibleAccountList />
          </div>
        </CardBody>
      </Collapse>
    </FilterableAccountListCard>
  );
});

const FilterableAccountListCard = styled(Card)`
  && {
    background-color: ${({ theme }) => theme.card.backgroundColor};
    border-color: ${({ theme }) => theme.borderColor};
  }
`;

const Header = styled(CardHeader)`
  && {
    background-color: ${({ theme }) => theme.card.header.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    border-color: ${({ theme }) => theme.borderColor};
  }
`;

const DropDownButton = styled.span`
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 0.25rem;
`;

export default FilterableAccountList;
