import * as React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { ConnectedLink } from '../../containers/ConnectedLink';
import * as config from '../../config';
import { logClick } from '../../util/clickLogger';
import styled from 'styled-components';
import { useDecision } from '@optimizely/react-sdk';
import { useTheme } from '../../styles/useTheme';
import { aprilFools } from '../../styles/theme';

export interface ModelProps {
  userEmail: string;
}

export type Props = ModelProps;

export const Navigation: React.FC<Props> = (props) => {
  const [new_arf_tab] = useDecision('new_arf_tab');
  const [old_arf_beside_new] = useDecision('old_arf_beside_new');
  const [theme] = useTheme();
  const isAprilFoolsTheme = theme.id === aprilFools.id;

  return (
    <div>
      <Nav tabs={true} card={true} color="primary">
        <StyledNavItem>
          <StyledNavLink
            tag={ConnectedLink}
            to={config.default.routes.overview}
            activeClassName="active"
            onClick={() => logClick('OverviewTab', props.userEmail)}
          >
            { isAprilFoolsTheme ? 'Dossier' : 'Overview' }
          </StyledNavLink>
        </StyledNavItem>
        <StyledNavItem>
          <StyledNavLink
            tag={ConnectedLink}
            to={config.default.routes.iamRoles}
            activeClassName="active"
            onClick={() => logClick('IAMTab', props.userEmail)}
          >
            IAM Roles
          </StyledNavLink>
        </StyledNavItem>
        {new_arf_tab.enabled ? (
          <StyledNavItem>
            <StyledNavLink
              tag={ConnectedLink}
              to={config.default.routes.arf}
              exact={true}
              activeClassName="active"
              onClick={() => logClick('ArfTab', props.userEmail)}
            >
              ARF
            </StyledNavLink>
          </StyledNavItem>
        ) : (
          <StyledNavItem>
            <StyledNavLink
              tag={ConnectedLink}
              to={config.default.routes.arf}
              exact={true}
              activeClassName="active"
              onClick={() => logClick('ArfTab', props.userEmail)}
            >
              ARF
            </StyledNavLink>
          </StyledNavItem>
        )}
        {old_arf_beside_new.enabled && new_arf_tab.enabled && (
          <StyledNavItem>
            <StyledNavLink
              tag={ConnectedLink}
              to={config.default.routes.oldArf}
              exact={true}
              activeClassName="active"
              onClick={() => logClick('ArfTab', props.userEmail)}
            >
              ARF (Legacy)
            </StyledNavLink>
          </StyledNavItem>
        )}
        <StyledNavItem>
          <StyledNavLink
            tag={ConnectedLink}
            to={config.default.routes.userManagement}
            activeClassName="active"
            onClick={() => logClick('UsersTab', props.userEmail)}
          >
            { isAprilFoolsTheme ? 'Variants' : 'Users' }
          </StyledNavLink>
        </StyledNavItem>
        <StyledNavItem>
          <StyledNavLink
            tag={ConnectedLink}
            to={config.default.routes.preferences}
            activeClassName="active"
            onClick={() => logClick('PreferencesTab', props.userEmail)}
          >
            Preferences
          </StyledNavLink>
        </StyledNavItem>
      </Nav>
    </div>
  );
};

const StyledNavItem = styled(NavItem)`
  && {
    outline: none;
  }

  && .nav-link:hover {
    border-color: ${({ theme }) => theme.borderColor};
    border-bottom-color: rgba(0, 0, 0, 0);
  }
`;

const StyledNavLink = styled(NavLink)`
  && {
    color: ${({ theme }) => theme.textColor};
  }

  &&.active, &&.active:hover {
    color: ${({ theme }) => theme.textColor};
    background-color: ${({ theme }) => theme.card.backgroundColor};
    border-color: ${({ theme }) => theme.borderColor};
    border-bottom-color: ${({ theme }) => theme.card.backgroundColor};
  }
`;

export default Navigation;
