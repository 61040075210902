import * as React from 'react';
import Gravatar from 'react-gravatar';
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarToggler,
  Collapse,
} from 'reactstrap';
import {
  InfoIcon,
  BookIcon,
  SignOutIcon,
  KeyIcon,
  PersonIcon,
} from '@primer/octicons-react';
import { Announcement } from '../../models';
import { Link } from 'react-router-dom';
import * as config from '../../config';
import styled from 'styled-components';
import { OktaAuth } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { ThemeSelector } from './ThemeSelector';
import { useDecision } from '@optimizely/react-sdk';
import { aprilFools, spooky } from '../../styles/theme';
import { christmas } from '../../styles/theme';
import { useTheme } from '../../styles/useTheme';

export interface ModelProps {
  announcements: Announcement[];
  announcementsRead: Record<string, boolean>;
  username: string;
  email: string;
}

export interface DispatchProps {
  showAnnouncement: (announcementId: string) => void;
  logout: (auth: OktaAuth) => void;
}

export type Props = DispatchProps & ModelProps;

const NavigationBar: React.FC<Props> = React.memo((props) => {
  const { REACT_APP_GIT_HASH } = process.env;

  const { oktaAuth } = useOktaAuth();

  const [spooky_theme_decision] = useDecision('spooky_theme');
  const [april_fools_theme_decision] = useDecision('april_fools');
  const [theme] = useTheme();

  // const handleNewsClick = (id: string) => () => {
  //   props.showAnnouncement(id);
  // };
  //
  // let announcements: Announcement[] = [];
  // if (props.announcements) {
  //   announcements = props.announcements;
  // }
  // let unreadAnnouncements = 0;
  // announcements.forEach((announcement) => {
  //   // if the particular announcement has not been read then increase the unread counter
  //   if (!props.announcementsRead[announcement.id]) {
  //     unreadAnnouncements++;
  //   }
  // });

  const [isOpen, setOpen] = React.useState(false);
  const toggleOpen = () => {
    setOpen(!isOpen);
  };

  let showNotification = false;
  let notificationColor: string | undefined = undefined;
  if (spooky_theme_decision.enabled || april_fools_theme_decision.enabled) {
    showNotification = true;
    notificationColor = spooky.primaryColor;
  }

  let icon = theme.id === aprilFools.id ? <>
    <a target='_blank' href="https://marvelcinematicuniverse.fandom.com/wiki/Time_Variance_Authority" >
      <img src="CVA_top.png" alt="Cloud Variance Authority" height="40px" />
      <img src="CVA_bottom.png" alt="For all cloud always" height="40px" />
    </a>
  </> : <>
    <img src="favicon/favicon-32x32.png" />
    <img src="ALKS.svg" alt="ALKS" height="40px" />
  </>
  if (theme.id == christmas.id) {
    icon = <img src="holiday.png" alt="ALKS" height="60px"  style={{position: 'relative', top: '-10px', left: '-10px', marginBottom: '-20px'}} />
  }

  return (
    <div>
      <StyledNavbar expand="md">
        <StyledNavbarBrand href="/">
          {icon}
        </StyledNavbarBrand>
        <NavbarToggler onClick={toggleOpen} />
        <Collapse isOpen={isOpen} navbar={true}>
          <Nav navbar={true} className="ms-auto">
            {/* <UncontrolledDropdown id="news" nav={true} inNavbar={true}>
              <StyledDropdownToggle nav={true} caret={true}>
                <BellIcon /> News{' '}
                {unreadAnnouncements > 0 && (
                  <Badge id="newsCounter" color="danger">
                    {unreadAnnouncements}
                  </Badge>
                )}
              </StyledDropdownToggle>
              <StyledDropdownMenu right={true}>
                {announcements.length === 0 ? (
                  <DropdownItem id="noNews">No news yet...</DropdownItem>
                ) : null}
                {announcements.map((announcement) => {
                  return (
                    <StyledDropdownItem
                      key={announcement.id}
                      onClick={handleNewsClick(announcement.id)}
                    >
                      {announcement.previewText}{' '}
                      <Badge
                        id="previewReadIcon"
                        hidden={props.announcementsRead[announcement.id]}
                        color="danger"
                      >
                        !
                      </Badge>
                    </StyledDropdownItem>
                  );
                })}
              </StyledDropdownMenu>
            </UncontrolledDropdown> */}
            <NavItem id="help">
              <StyledNavLink
                target="_blank"
                rel="noopener noreferrer"
                href="https://coxautoinc.sharepoint.com/sites/service-internal-tools-team/SitePages/ALKS-Web-UI.aspx"
              >
                <InfoIcon /> Help
              </StyledNavLink>
            </NavItem>
            <NavItem id="swagger">
              <StyledNavLink
                target="_blank"
                rel="noopener noreferrer"
                href={config.default.docs.swagger}
              >
                <BookIcon /> Swagger Docs
              </StyledNavLink>
            </NavItem>
            <UncontrolledDropdown id="user" nav={true} inNavbar={true}>
              <StyledDropdownToggle nav={true} caret={true}>
                <StyledGravatar
                  showNotification={showNotification}
                  notificationColor={notificationColor}
                  email={props.email}
                  size={30}
                  default="robohash"
                  style={{ borderRadius: 15 }}
                />{' '}
              </StyledDropdownToggle>
              <StyledDropdownMenu right={true}>
                <StyledDropdownItem header={true}>
                  Logged in as:
                </StyledDropdownItem>
                <StyledDropdownItem disabled={true}>
                  {props.username ? props.username : 'Username lookup failed'}
                </StyledDropdownItem>
                <StyledDropdownItem divider={true} />
                <StyledDropdownItem>
                  <ThemeSelector />
                </StyledDropdownItem>
                <StyledDropdownItem>
                  <StyledNavLink tag={Link} to="/token-management">
                    <KeyIcon /> Token Management
                  </StyledNavLink>
                </StyledDropdownItem>
                <StyledDropdownItem>
                  <StyledNavLink
                    href={config.default.docs.profile_pics}
                    target="_blank"
                  >
                    <PersonIcon /> Change Profile Picture
                  </StyledNavLink>
                </StyledDropdownItem>
                <StyledDropdownItem>
                  <StyledNavLink
                    tag={Link}
                    to="/"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      props.logout(oktaAuth);
                    }}
                  >
                    <SignOutIcon /> Log Out
                  </StyledNavLink>
                </StyledDropdownItem>
                <StyledDropdownItem divider={true} />
                <StyledDropdownItem disabled={true}>
                  Version: {REACT_APP_GIT_HASH}
                </StyledDropdownItem>
              </StyledDropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </StyledNavbar>
    </div>
  );
});

const StyledNavbar = styled(Navbar)`
  background-color: ${({ theme }) => theme.navbar.backgroundColor};
`;

const StyledNavbarBrand = styled(NavbarBrand)`
  && {
    padding: 0;
    color: ${({ theme }) => theme.textColor};
  }
`;

const StyledDropdownToggle = styled(DropdownToggle)`
  && {
    color: ${({ theme }) => theme.textColor};
  }
`;

const StyledNavLink = styled(NavLink)`
  && {
    color: ${({ theme }) => theme.textColor};
  }
`;

const StyledDropdownMenu = styled(DropdownMenu)`
  && {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    border-color: ${({ theme }) => theme.borderColor};
  }
`;

const StyledDropdownItem = styled(DropdownItem)`
  && {
    color: ${({ theme }) => theme.textColor};
  }

  &&:hover,
  &&:focus {
    color: ${({ theme }) => theme.navbar.dropdown.hover.textColor};
    background-color: ${({ theme }) =>
      theme.navbar.dropdown.hover.backgroundColor};
  }
`;

const StyledGravatar = styled(Gravatar)<{
  showNotification: boolean;
  notificationColor: string | undefined;
}>`
  @keyframes example {
    from {
      box-shadow: 0px 0px 5px 1px
        ${({ notificationColor, theme }) =>
          notificationColor ?? theme.primaryColor};
    }
    to {
      box-shadow: 0px 0px 5px 5px
        ${({ notificationColor, theme }) =>
          notificationColor ?? theme.primaryColor};
    }
  }

  && {
    animation-name: ${({ showNotification }) =>
      showNotification ? 'example' : 'none'};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
`;

export default NavigationBar;
