import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';
import ActiveAccount from '../../containers/ActiveAccount';
import { IamRoles } from '../iamRole/IamRoles';
import Preference from '../preferences/Preference';
import { ActiveAccountRoute } from '../../containers/ActiveAccountRoute';
import { ConnectedSwitch } from '../../containers/ConnectedSwitch';
import styles from './AccountPanel.module.scss';
import { ActiveAccountIamRolesFetcher } from '../../containers/ActiveAccountIamRolesFetcher';
import * as config from '../../config';
import VisibleUserManagement from '../../containers/VisibleUserManagement';
import { Arf as OldArf } from '../old_arf/Arf';
import { VisibleNavigation } from '../../containers/VisibleNavigation';
import styled from 'styled-components';
import Fireworks from '../seasonal/Fireworks';
import { useDecision } from '@optimizely/react-sdk';
import { ConnectedArf } from '../../containers/ConnectedArf';

export const AccountPanel: React.FC = () => {
  const [fourthOfJuly] = useDecision('fourth_of_july');
  const [new_arf_tab] = useDecision('new_arf_tab');
  const [old_arf_beside_new] = useDecision('old_arf_beside_new');

  return (
    <AccountPanelCard className={styles.accountPanel}>
      <ActiveAccountIamRolesFetcher />
      <StyledHeader className={styles.header}>
        <VisibleNavigation />
      </StyledHeader>
      {fourthOfJuly.enabled && <Fireworks />}
      <CardBody className={styles.content}>
        <ConnectedSwitch>
          <ActiveAccountRoute
            path={config.default.routes.overview}
            component={ActiveAccount}
          />
          <ActiveAccountRoute
            path={config.default.routes.iamRoles}
            component={IamRoles}
          />
          <ActiveAccountRoute
            path={config.default.routes.preferences}
            component={Preference}
          />
          {new_arf_tab.enabled ? (
            <ActiveAccountRoute
              path={config.default.routes.arf}
              component={ConnectedArf}
              exact={true}
            />
          ) : (
            <ActiveAccountRoute
              path={config.default.routes.arf}
              component={OldArf}
              exact={true}
            />
          )}
          {old_arf_beside_new.enabled && new_arf_tab.enabled && (
            <ActiveAccountRoute
              path={config.default.routes.oldArf}
              component={OldArf}
              exact={true}
            />
          )}
          <ActiveAccountRoute
            path={config.default.routes.userManagement}
            component={VisibleUserManagement}
          />
          <Redirect
            from={config.default.routes.index}
            to={config.default.routes.overview}
          />
        </ConnectedSwitch>
      </CardBody>
    </AccountPanelCard>
  );
};

export const AccountPanelCard = styled(Card)`
  && {
    background-color: ${({ theme }) => theme.card.backgroundColor};
    border-color: ${({ theme }) => theme.borderColor};
  }
`;

const StyledHeader = styled(CardHeader)`
  && {
    background-color: ${({ theme }) => theme.card.header.backgroundColor};
    border-color: ${({ theme }) => theme.borderColor};
  }
`;

export default AccountPanel;
