import * as React from 'react';
import { CardBody, Col, Container, Row, Table } from 'reactstrap';
import * as Model from '../../models';
import keyValueTableStyles from '../../styles/KeyValueTable.module.css';
import getRolePriority from '../../util/getRolePriority';
import { CircularProgress } from '@mui/material';
import styles from './Account.module.scss';
import { DataFetchStatus } from '../../store/shared/types';
import styled from 'styled-components';
import { OktaAuth } from '@okta/okta-auth-js';
import { useCallback } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { ActiveAccountTagBadge } from '../../containers/ActiveAccountTagBadge';
import { Role } from './Role';

export interface ModelProps {
  account?: Model.AccountView;
  accountRoles: Model.RoleView[];
  fetchKeyStatus: DataFetchStatus;
}

export interface DispatchProps {
  getKeys: (
    auth: OktaAuth,
    account: Model.Account,
    role: Model.Role,
    duration: number
  ) => void;
  setPreferredRole: (accountId: string, roleId: string) => void;
}

export type Props = ModelProps & DispatchProps;

const DURATIONS = [1, 2, 6, 12];

const initialState = {
  dropDownOpen: new Map<string, boolean>(),
};

export type State = Readonly<typeof initialState>;

const Account: React.FC<Props> = ({
  account,
  accountRoles,
  fetchKeyStatus,
  getKeys,
  setPreferredRole,
}) => {
  const { oktaAuth } = useOktaAuth();

  const handleGetKeys = useCallback(
    (account: Model.Account, role: Model.Role, duration: number) => {
      getKeys(oktaAuth, account, role, duration);
    },
    [getKeys, oktaAuth]
  );

  const handleSetPreferredRole = useCallback(
    (accountId: string, roleId: string) => {
      setPreferredRole(accountId, roleId);
    },
    [setPreferredRole]
  );

  if (!account) {
    return <h2>Sorry, you've got no active accounts. :(</h2>;
  }

  if (!accountRoles.length) {
    return <h2>Sorry, you're not assigned any roles for this account. :(</h2>;
  }

  const preferredRoleId = getPreferredRoleId(account, accountRoles);
  const roles = accountRoles.map((role) => {
    const roleDurations = [
      ...DURATIONS.filter((duration) => role.maxKeyDuration > duration),
      role.maxKeyDuration,
    ];

    return (
      <Role
        key={role.id}
        role={role}
        account={account}
        isPreferredRole={role.id === preferredRoleId}
        durations={roleDurations}
        getKeys={handleGetKeys}
        setPreferredRole={handleSetPreferredRole}
      />
    );
  });

  const isPending = fetchKeyStatus === DataFetchStatus.DATA_FETCH_PENDING;

  return (
    <AccountCardBody>
      <Container>
        <Row>
          <KeyValueTable
            borderless={true}
            className={keyValueTableStyles.keyValueTable}
          >
            <tbody>
              <tr>
                <th>Account ID:</th>
                <td>{account.id}</td>
              </tr>
              <tr>
                <th>Account Alias:</th>
                <td>{account.name}</td>
              </tr>
              <tr>
                <th>Account Label:</th>
                <td>
                  {account.skypieaAccount
                    ? account.skypieaAccount.label
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <th>Security Level:</th>
                <td>L{account.securityLevel}</td>
              </tr>
              <tr>
                <th>Tags:</th>
                <td>
                  {Object.values(account.tags).map((tag) => (
                    <>
                      <ActiveAccountTagBadge tag={tag} closeable={false} />{' '}
                    </>
                  ))}
                </td>
              </tr>
            </tbody>
          </KeyValueTable>
        </Row>
        {/* adding div for christmas theme below */}
        <div className={styles.christmas}></div>
        <Row>
          <Col>{roles}</Col>
        </Row>

        {isPending ? Loading() : null}
      </Container>
    </AccountCardBody>
  );
};

const getPreferredRoleId = (
  account: Model.AccountView,
  roles: Model.Role[]
): string => {
  return account.preferredRole || getHighestAccessRole(roles).id;
};

const getHighestAccessRole = (roles: Model.Role[]): Model.Role => {
  // Clone the role list so we don't modify state
  const pRoles = [...roles].sort(
    (a, b) => getRolePriority(a) - getRolePriority(b)
  );
  return pRoles[0];
};

const Loading = () => {
  return (
    <div className={styles.loading}>
      <StyledCircularProgress size={300} />
    </div>
  );
};

const StyledCircularProgress = styled(CircularProgress)`
  && {
    color: ${({ theme }) => theme.primaryColor};
  }
`;

const KeyValueTable = styled(Table)`
  && {
    color: ${({ theme }) => theme.textColor};
    background-color: ${({ theme }) => theme.card.backgroundColor};
  }
`;

const AccountCardBody = styled(CardBody)`
  && {
    color: ${({ theme }) => theme.textColor};
  }
`;

export default Account;
